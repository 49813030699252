/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: '96%',
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer>
              <div className="imprint">
                  <div style={{
                      color: '#92908c',
                      fontSize: '11px',
                      lineHeight: '15px',
                      fontFamily: 'Montserrat, sans-serif'
                  }}>
                      Thomas Seifert | Jungfernsteig 5 | 13629 Berlin | +49 (0)176 637 762 22 | <a href="mailto:thomas@thomas-seifert.com" title="contact thomas seifert">thomas@thomas-seifert.com</a>
                      | Steuernummer: 18/531/00347<br />
                      S&auml;mtliche Fotos und Bildmaterialien dieser Webseite unterliegen dem
                      Urheberrecht! Verst&ouml;&szlig;e gegen das Urheberrechts-Gesetz werden sofort
                      und ohne Vorwarnung zur Anzeige gebracht und mit allen rechtlichen
                      Mitteln verfolgt!
                  </div>
              </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
